import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../layouts';
import styled from '@emotion/styled';

import { Tabs, Tab, Box } from '@material-ui/core';
import { Inner, TabPanel, tabProps } from '../components/shared';
import SEO from '../components/seo';
import { Globals, Breakpoints } from '../styles/global';

interface OrangeTile {
  title: string;
  link: string;
  content: string;
}

export const IndexPageTemplate = ({
  title,
  heroSubtitle,
  heroTitle,
  heroCopy,
  tagline,
  bOrangeTitle,
  bOrangeCopy,
  purpleTitle,
  purpleCopy,
  orangeTitle,
  orangeContent,
  yellowTitle,
  tile1Title,
  tile1Copy,
  tile2Title,
  tile2Copy,
  tile3Title,
  tile3Copy,
  orangeTiles,
}: {
  title: string;
  heroSubtitle: string;
  heroTitle: string;
  heroCopy: string;
  tagline: string;
  bOrangeTitle: string;
  bOrangeCopy: string;
  purpleTitle: string;
  purpleCopy: string;
  orangeTitle: string;
  orangeContent: string;
  yellowTitle: string;
  tile1Title: string;
  tile1Copy: string;
  tile2Title: string;
  tile2Copy: string;
  tile3Title: string;
  tile3Copy: string;
  orangeTiles: OrangeTile[];
}) => {
  const [tabValue, setTab] = React.useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  return (
    <React.Fragment>
      <SEO title={title} description={heroTitle} />
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Hero>
          <Inner>
            <TextWrap>
              <h1 className="font-b p-0 m-0 mb-d3 light">{heroSubtitle}</h1>
              <h2 className="font-u4 p-0 m-0 mb-d2 bold">{heroTitle}</h2>
              <h3 className="font-b p-0 m-0 mb-u4 light">{heroCopy}</h3>
              <div className="cta-wrap">
                <Link to="/fostering" className="button outline">
                  <span>Learn More</span>
                </Link>
              </div>
            </TextWrap>
          </Inner>
        </Hero>
        <Tagline>
          <Inner>
            <h3 className="font-b italic">{tagline}</h3>
          </Inner>
        </Tagline>
        <BloodBox minHeight={['30rem']} display="flex" alignItems="center">
          <Inner>
            <h2 className="font-u4 mb-u2 uppercase">{bOrangeTitle}</h2>
            <p className="font-u2 mb-u3 medium">{bOrangeCopy}</p>
            <Link to="/fostering" className="button outline font-u2">
              <span>Learn More</span>
            </Link>
            <IconP variant="house" />
            <IconP variant="clock" />
            <IconP variant="chat" />
          </Inner>
        </BloodBox>
        <PurpleBox minHeight={['30rem']} display="flex" alignItems="center">
          <Inner>
            <Copy>
              <h2 className="font-u4 mb-u2 uppercase">{purpleTitle}</h2>
              <p className="font-u2 mb-u3 medium">{purpleCopy}</p>
              <Link to="/adopting" className="button outline font-u2">
                <span>Learn More</span>
              </Link>
            </Copy>
            <IconP variant="heart" />
            <IconP variant="book" />
            <IconP variant="cubes" />
          </Inner>
        </PurpleBox>
        <OrangeBox>
          <OrangeTop />
          <Inner>
            <h3 className="font-u4 text-center">{orangeTitle}</h3>
            <p className="font-b text-center mb-u4 white">
              {orangeContent} <Link to="system-of-care">Learn More</Link>
            </p>
            <TileBox className="py-u3">
              {orangeTiles.length > 0 &&
                orangeTiles.map((tile, index) => (
                  <Link
                    className={`px-u1 py-u2 ${
                      ['hand', 'briefcase', 'cog'][index]
                    }`}
                    to={tile.link}
                    key={`tiles-${index}`}
                  >
                    <Box display="flex" justifyContent="center" flexWrap="wrap">
                      <Box height={'7rem'} paddingTop="0.5rem">
                        <img
                          src={
                            ['/hand.png', '/briefcase.png', '/cog.png'][index]
                          }
                          alt={`icon for ${tile.title}`}
                        />
                      </Box>
                      <Box minHeight="9rem">
                        <h2 className="font-u1 bold">{tile.title}</h2>
                        <p className="font-b">{tile.content}</p>
                      </Box>
                    </Box>
                  </Link>
                ))}
            </TileBox>
          </Inner>
        </OrangeBox>
        <YellowBox>
          <ChatBubble>
            <h4 className="font-xs-u2 font-sm-u3">{yellowTitle}</h4>
          </ChatBubble>
          <TileHolder className="font-u2 p-u2">
            <TileGrid>
              <TabPanel value={tabValue} index={0}>
                <BlueTile href="https://charity.gofundme.com/o/en/donate-widget/26140">
                  <ImgWrap>
                    <img src="/donate-img.png" alt={tile2Title} />
                  </ImgWrap>
                  <h3 className="font-u1">{tile1Title}</h3>
                  <p className="font-b">{tile1Copy}</p>
                </BlueTile>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <BlueTile href="https://www.myflfamilies.com/service-programs/abuse-hotline/">
                  <ImgWrap>
                    <img src="/volunteer.png" alt={tile1Title} />
                  </ImgWrap>
                  <h3 className="font-u1">{tile2Title}</h3>
                  <p className="font-b">{tile2Copy}</p>
                </BlueTile>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <BlueTile href="https://smile.amazon.com/ch/59-1865751">
                  <ImgWrap>
                    <img src="/amazon-smile.png" alt={tile3Title} />
                  </ImgWrap>
                  <h3 className="font-u1">{tile3Title}</h3>
                  <p className="font-b"> {tile3Copy}</p>
                </BlueTile>
              </TabPanel>

              <DotTabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label={`Steps to`}
                variant="fullWidth"
              >
                <DotTab label="1" {...tabProps(0)} />
                <DotTab label="2" {...tabProps(1)} />
                <DotTab label="3" {...tabProps(2)} />
              </DotTabs>
            </TileGrid>
          </TileHolder>
        </YellowBox>
      </Box>
    </React.Fragment>
  );
};

const IndexPage = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title = '',
        heroSubtitle = '',
        heroTitle = '',
        heroCopy = '',
        tagline = '',
        bOrangeTitle = '',
        bOrangeCopy = '',
        purpleTitle = '',
        purpleCopy = '',
        orangeTitle = '',
        orangeContent = '',
        tile1Title = '',
        tile1Copy = '',
        tile2Title = '',
        tile2Copy = '',
        tile3Title = '',
        tile3Copy = '',
        yellowTitle = '',
        orangeTiles = [],
      },
    },
  },
}) => {
  return (
    <Layout>
      <IndexPageTemplate
        title={title}
        heroSubtitle={heroSubtitle}
        heroTitle={heroTitle}
        heroCopy={heroCopy}
        tagline={tagline}
        bOrangeTitle={bOrangeTitle}
        bOrangeCopy={bOrangeCopy}
        purpleTitle={purpleTitle}
        purpleCopy={purpleCopy}
        orangeTitle={orangeTitle}
        orangeContent={orangeContent}
        orangeTiles={orangeTiles}
        yellowTitle={yellowTitle}
        tile1Title={tile1Title}
        tile1Copy={tile1Copy}
        tile2Title={tile2Title}
        tile2Copy={tile2Copy}
        tile3Title={tile3Title}
        tile3Copy={tile3Copy}
      />
    </Layout>
  );
};

export default IndexPage;

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        heroSubtitle
        heroTitle
        heroCopy
        tagline
        bOrangeTitle
        bOrangeCopy
        purpleTitle
        purpleCopy
        orangeTitle
        orangeContent
        yellowTitle
        tile1Title
        tile1Copy
        tile2Title
        tile2Copy
        tile3Title
        tile3Copy
        orangeTiles {
          title
          link
          content
        }
      }
    }
  }
`;

const iconMap = {
  house: {
    url: '/house-icon.png',
    s: '6rem',
    r: '30%',
    t: 'auto',
    l: 'auto',
    b: '-2.5rem',
  },
  heart: {
    url: '/heart.png',
    s: '6rem',
    r: 'auto',
    t: '5rem',
    l: '1rem',
    b: 'auto',
  },
  cubes: {
    url: '/cubes.png',
    s: '6rem',
    r: 'auto',
    t: '2rem',
    l: '14rem',
    b: 'auto',
  },
  chat: {
    url: '/chat-bubble.png',
    s: '6rem',
    r: '2rem',
    b: '1rem',
    l: 'auto',
    t: 'auto',
  },
  clock: {
    url: '/clock.png',
    s: '6rem',
    r: '1rem',
    t: '1rem',
    l: 'auto',
    b: 'auto',
  },
  book: {
    url: '/book.png',
    s: '6rem',
    r: 'auto',
    t: 'auto',
    l: '8rem',
    b: '6rem',
  },
};

const IconP = styled.div<{
  variant: 'house' | 'heart' | 'cubes' | 'chat' | 'book' | 'clock';
}>`
  pointer-events: none;
  background-image: url(${props => iconMap[props.variant].url});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;

  width: ${props => iconMap[props.variant].s};
  height: ${props => iconMap[props.variant].s};

  top: ${props => iconMap[props.variant].t};
  right: ${props => iconMap[props.variant].r};
  bottom: ${props => iconMap[props.variant].b};
  left: ${props => iconMap[props.variant].l};
`;

const Copy = styled.div`
  max-width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  justify-self: flex-end;
  justify-content: flex-end;
  text-align: right;
`;

const PurpleBox = styled(Box)`
  width: 100%;
  background-color: ${Globals.purple};
  color: ${Globals.white};
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  ${Inner} {
    position: relative;
    z-index: 1;
    &::before {
      z-index: 0;
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      opacity: 0.5;
      background-image: url('/crosses-2.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
      pointer-events: none;
    }
  }
`;

const BloodBox = styled(Box)`
  width: 100%;
  background-color: ${Globals.red};
  color: ${Globals.white};
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  ${Inner} {
    position: relative;
    z-index: 1;
    &::before {
      pointer-events: none;
      z-index: 0;
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      opacity: 0.5;
      background-image: url('/crosses-1.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
    }
  }
`;

const OrangeTop = styled.div`
  background-image: url('/orangeSectionBackground.png');
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;
  height: 20rem;
  background-size: contain;
  width: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    background-image: url('/orangeBubbles.png');
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center bottom;
    left: 0;
    bottom: 0;
    right: 0;
    height: 30rem;
    background-size: contain;
  }
  ${Breakpoints.sm`

    height: 60rem;
    background-size: cover;

  `}
`;

const OrangeBox = styled.div`
  width: 100%;
  background-color: ${Globals.orange};
  color: ${Globals.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    color: ${Globals.white};
  }
  ${Inner} {
    padding: 4rem 2rem;
  }
  h2 {
    width: 100%;
  }
`;

const TileBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    color: ${Globals.white};
    text-decoration: none;
    flex-wrap: wrap;
    text-align: center;
    justify-content: flex-start;
    width: 100%;
    align-items: space-between;
    margin-bottom: 2rem;
    &:hover {
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
    }
    ${Breakpoints.md`
      width: calc(33% - 1rem);
    `}
  }

  a.hand img,
  a.cog img {
    max-width: 5rem;
    height: auto;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 5rem;
  }
`;

const ChatBubble = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  overflow: visible;
  &::before {
  pointer-events: none;
    position: absolute;
    content: '';
    width: 100%;
    background-color: #fff;
    min-height: 22rem;
    z-index: 0;
    ${Breakpoints.sm`

      min-height: 10rem;
    `}

  }
  ${Breakpoints.lg`

      &::before {
        min-height: 18rem;
      }
  `}

  color: ${Globals.purple};
  background-image: url('/chatBubble.png');
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center bottom;
  padding-bottom: 5rem;
  background-size: cover;
  width: 100%;
  height: 45rem;
  h4 {
    padding: 0 2rem 2rem;
    margin-top: 3rem;
    text-align: center;
    max-width: 60rem;
    position: relative;
    z-index: 1;
  }

  ${Breakpoints.sm`

  height: 55rem;
  h4 {
    padding-top: 4rem;
  }

  `}
`;
const YellowBox = styled.div`
  width: 100%;
  background-color: ${Globals.yellow};
  color: ${Globals.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-decoration: none;
  ${Inner} {
    padding: 4rem 2rem 8rem;
  }
`;

const TileHolder = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0 2rem;
  justify-content: center;
  max-width: 75rem;
`;

const TileGrid = styled(Box)`
  margin-top: 2rem;
  display: flex;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  > div {
    width: 100%;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 2rem;
`;

const BlueTile = styled.a`
  color: ${Globals.white};
  padding: 2rem;
  border-radius: 2rem 0 2rem 0;
  max-width: 20rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  &:hover {
    h3 {
      text-decoration: underline;
    }
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;
const TextWrap = styled.div`
  position: relative;
  bottom: 10%;
  left: 0;
  right: 0;
  z-index: 1;
  color: ${Globals.white};
  .cta-wrap {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  ${Breakpoints.sm`
    .cta-wrap {
      justify-content: flex-start;
      margin-top: 0.25rem;
    }
  `}

  q,
  cite {
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    ${Breakpoints.sm`
      color: ${Globals.cream};
      font-size: 1.5rem;
      line-height: 1.4;
    `}
    ${Breakpoints.md`
      font-size: 2rem;
      line-height: 1.4;
    `}
  }
`;
const Hero = styled.div`
  width: 100%;
  display: flex;
  padding: 6rem 0 4rem;
  max-width: 150rem;
  ${Inner} {
    padding: 0 2rem;
  }
  justify-content: center;
  align-items: flex-end;
  position: relative;
  background-color: black;
  background-image: url('/homepage-hero.jpg');
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  ${Breakpoints.sm`
    min-height: 20rem;
  `}
  ${Breakpoints.md`
    min-height: 40rem;
  `}
`;

const Tagline = styled.div`
  width: 100%;
  padding: 0 4rem;
  margin: 2.5rem 0;
  text-align: center;
  justify-content: center;
  display: flex;
  ${Inner} {
    box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  }
  h3 {
    margin: 3rem 0 2.5rem;
    padding: 2rem 2rem;
    border-top: 0.0125rem solid ${Globals.yellow};
    border-bottom: 0.0125rem solid ${Globals.yellow};
    color: ${Globals.orange};
    ${Breakpoints.sm`
      margin: 3rem 4rem 2.5rem;
    `}
  }
`;

const DotTabs = styled(Tabs)`
  &,
  & [class*='-scroller'] {
    display: flex;
    justify-content: center;
  }
  & [class*='-indicator'] {
    display: none;
  }
`;

const DotTab = styled(Tab)`
  &.step-1-color {
    background-color: rgba(20, 20, 20, 0.4);
  }
  &.step-2-color {
    background-color: rgba(20, 20, 20, 0.4);
  }
  &.step-3-color {
    background-color: rgba(20, 20, 20, 0.4);
  }
  &:hover {
    background-color: rgba(20, 20, 20, 0.2);
  }

  margin: 0 10px;
  color: transparent;
  padding: 10px;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  &[class*='-selected'],
  &:hover {
    box-shadow: 0 0 1px rgba(20, 20, 20, 0.2);
  }
`;
